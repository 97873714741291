import { getDefaultFilters } from './store';
import type { Page } from '@sveltejs/kit';
import type { IFilters } from './types';
import { browser } from '$app/environment';

function getSetIntersection<T>(...sets: Set<T>[]) {
  return sets.reduce((acc, currentSet) => {
    return new Set(Array.from(acc).filter((item) => currentSet.has(item)));
  });
}

export function getLessonMode(lessonMode: string[]) {
  if (lessonMode.includes('Live Streaming/Roma')) {
    return [...lessonMode, 'Roma'];
  }

  return lessonMode;
}

export function getCourseType(courseType: string[], studentType?: string[]) {
  const courseTypeSet = new Set(courseType);
  const studentTypeSet = new Set<string>();

  if (studentType && studentType.length > 0) {
    studentType.forEach((type) => {
      if (type === 'Neo Laureati') {
        studentTypeSet.add('Master Post Laurea con Stage');
        studentTypeSet.add('MBA Full Time');
        studentTypeSet.add('Corsi Online');
        studentTypeSet.add('Masterclass');
      } else if (type === 'Neo Diplomati') {
        studentTypeSet.add('Corsi Online');
        studentTypeSet.add('Masterclass');
        studentTypeSet.add('Corsi Laurea');
      } else if (type === 'Professionisti') {
        studentTypeSet.add('Executive Master');
        studentTypeSet.add('Professional Master');
        studentTypeSet.add('Corsi Executive');
        studentTypeSet.add('Executive MBA');
        studentTypeSet.add('MBA Full Time');
        studentTypeSet.add('MBA Part Time');
        studentTypeSet.add('Corsi Online');
        studentTypeSet.add('Masterclass');
      } else if (type === 'Executive') {
        studentTypeSet.add('Executive Master');
        studentTypeSet.add('Professional Master');
        studentTypeSet.add('Corsi Executive');
        studentTypeSet.add('Executive MBA');
        studentTypeSet.add('MBA Part Time');
        studentTypeSet.add('Corsi Online');
        studentTypeSet.add('Masterclass');
      }
    });
  }

  if (courseTypeSet.size === 0 && studentTypeSet.size !== 0) {
    return Array.from(studentTypeSet);
  } else if (courseTypeSet.size !== 0 && studentTypeSet.size === 0) {
    return Array.from(courseTypeSet);
  } else if (courseTypeSet.size === 0 && studentTypeSet.size === 0) {
    return [];
  }

  const finalSet = getSetIntersection(courseTypeSet, studentTypeSet);

  if (finalSet.size === 0) {
    return ['-'];
  }

  return Array.from(finalSet);
}

export function getDurationInMonths(slug: string) {
  const d = slug.split('-');
  const duration = parseInt(d[0]);
  const durationType = d[1];

  let durationInMonths;

  if (durationType === 'ora' || durationType === 'ore') {
    durationInMonths = duration * 0.0014;
  } else if (
    durationType === 'giornate' ||
    durationType === 'giornata' ||
    durationType == 'serate'
  ) {
    durationInMonths = duration * 0.0329;
  } else if (durationType == 'weekend') {
    durationInMonths = duration * 0.0657;
  } else if (durationType === 'mesi' || durationType === 'mese') {
    durationInMonths = duration;
  }

  return durationInMonths;
}

export function getDurationQuery(months: number) {
  const days = months * 30;
  const evenings = months * 30;
  const hours = days * 24;
  const weekends = months * 4;

  return `(or 
    (and duration_type:'giornate' (range field=duration {,${days}])) 
    (and duration_type:'giornate' (range field=duration {,${days}])) 
    (and duration_type:'serate' (range field=duration {,${evenings}])) 
    (and duration_type:'weekend' (range field=duration {,${weekends}]))
    (and duration_type:'mesi' (range field=duration {,${months}]))
    (and duration_type:'mese' (range field=duration {,${months}])) 
    (and duration_type:'ore' (range field=duration {,${hours}]))
    (and duration_type:'ora' (range field=duration  {,${hours}]) )
  ) `;
}

export const toArray = (value: any[] | any, transform: (item: any) => any = (item) => item) => {
  if (Array.isArray(value)) {
    return value.map(transform);
  }
  return [transform(value)];
};

export const handleFilters = (value: Page) => {
  const queryFilters: IFilters = getDefaultFilters();

  if (browser && value.url.searchParams.size > 0) {

    for (const filter in queryFilters) {
      const vFilter = value.url.searchParams.get(filter);
      if (vFilter && typeof vFilter === 'string' && vFilter.includes(';')) {
        queryFilters[filter] = vFilter.split(';');
      } else if (vFilter && typeof vFilter === 'string') {
        queryFilters[filter] = vFilter;
      }
    }

    queryFilters.courseType = toArray(queryFilters.courseType);
    queryFilters.category = toArray(queryFilters.category);
    queryFilters.lessionMode = toArray(queryFilters.lessionMode);
    queryFilters.studentType = toArray(queryFilters.studentType);
    queryFilters.month = toArray(queryFilters.month);
    queryFilters.duration = toArray(queryFilters.duration, (item) => parseInt(item));
  }

  return queryFilters;
};

export const mergeJSON = (json1, json2) => {
  const mergedJSON = {};

  // Itera attraverso le chiavi di json1
  for (let key in json1) {
    if (json1.hasOwnProperty(key)) {
      if (json2.hasOwnProperty(key)) {
        if (json1[key] && !json2[key]) {
          mergedJSON[key] = json1[key];
        } else if (!json1[key] && json2[key]) {
          mergedJSON[key] = json2[key];
        } else if (json1[key] && json2[key]) {
          // If is find the same key and both are arrays, merge them
          if (Array.isArray(json1[key]) && Array.isArray(json2[key])) {
            mergedJSON[key] = [...json1[key], ...json2[key]];
          } else {
            mergedJSON[key] = json1[key]; // Prioritize json1's value
          }
        }
        // If both are falsy, you may decide how to handle it
      } else {
        mergedJSON[key] = json1[key];
      }
    }
  }

  // Itera attraverso le chiavi di json2
  for (let key in json2) {
    if (json2.hasOwnProperty(key) && !mergedJSON.hasOwnProperty(key)) {
      mergedJSON[key] = json2[key];
    }
  }

  return mergedJSON;
}
